import * as types from "./actionTypes";
import { auth, facebookAuthProvider, googleAuthProvider } from "../firebase";
import { type } from "@testing-library/user-event/dist/type";

const signupStart = () => ({
    type: types.SIGNUP_START,
})

const signupSuccess = (user) => ({
    type: types.SIGNUP_SUCCESS,
    payload: user
})

const signupFail = (error) => ({
    type: types.SIGNUP_FAIL,
    payload: error
})

const signinStart = () => ({
    type: types.SIGNIN_START,
})

const signinSuccess = (user) => ({
    type: types.SIGNIN_SUCCESS,
    payload: user
})

const signinFail = (error) => ({
    type: types.SIGNIN_FAIL,
    payload: error
})

const logoutStart = () => ({
    type: types.LOGOUT_START,
})

const logoutSuccess = () => ({
    type: types.LOGOUT_SUCCESS,
})

const logoutFail = (error) => ({
    type: types.LOGOUT_FAIL,
    payload: error
})

export const setUser = (user) => ({
    type: types.SET_USER,
    payload: user,
})

const googleSignInStart = () => ({
    type: types.GOOGLE_SIGN_IN_START,
})

const googleSignInSuccess = (user) => ({
    type: types.GOOGLE_SIGN_IN_SUCCESS,
    payload: user,
})

const googleSignInFail = (error) => ({
    type: types.GOOGLE_SIGN_IN_FAIL,
    payload: error,
})

export const signupInitiate = (email, password, displayName) => {
    return function (dispatch) {
        dispatch(signupStart());
        auth.createUserWithEmailAndPassword(email, password).then(({user}) => {
            user.updateProfile({
                displayName
            })
            dispatch(signupSuccess(user))
        }).catch((error) => dispatch(signupFail(error.message)))
    }
}

export const signinInitiate = (email, password) => {
    return function (dispatch) {
        dispatch(signinStart());
        auth.signInWithEmailAndPassword(email, password).then(({user}) => {
            dispatch(signinSuccess(user))
        }).catch((error) => dispatch(signinFail(error.message)))
    }
}

export const googleSignInInitiate = () => {
    return function (dispatch) {
        dispatch(googleSignInStart());
        auth.signInWithPopup(googleAuthProvider).then(({user}) => {
            dispatch(googleSignInSuccess(user))
        })
        .catch((error) => dispatch(googleSignInFail(error.message)))
    }
}

export const logoutInitiate = () => {
    return function (dispatch) {
        dispatch(logoutStart());
        auth.signOut()
        .then((res) => dispatch(logoutSuccess()))
        .catch((error) => dispatch(logoutFail(error)))
    }
}