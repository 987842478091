import React from 'react'
import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import { useState, useEffect, useRef } from 'react';
import Piece from './Piece';

import { Avatar, CssBaseline, Container, Paper, TextField, FormControlLabel, Checkbox, Button, Link } from '@mui/material';

import axios from 'axios'

import "./board.css";

const Board = () => {
  const letters = ["a", "b", "c", "d", "e", "f", "g", "h"];
  const reverseLetters = ["h", "g", "f", "e", "d", "c", "b", "a"]

  // State variable for fetching puzzle   
  const [fetchingPuzzle, setFetchingPuzzle] = useState(true);

  // State variable to hold error   
  const [error, setError] = useState(null);

  // 2D array that will hold piece in each square
  const [piecePositions, setPiecePositions] = useState(null);
  // State variable for when pieces are set   
  const [piecesLoaded, setPiecesLoaded] = useState(false);

  // Initial FE notation string that is fetched   
  const [fenString, setFenString] = useState("");

  // Moves arr with two strings, black's initial move and white's move   
  const [moves, setMoves] = useState(null);
  // Color to move (who's turn it is)   
  const [colorToMove, setColorToMove] = useState("");

  // Save last piece clicked to clear potential moves
  let lastPieceClicked = null;
  
  // Check move once player makes it 
  let count = 0;
  let correctMoveMade = null;


  const convertFENRow = (rowNotation) => {
    let arr = [];
    let idx = 0;

    for (let i = 0; i < rowNotation.length; i++) {
        let fenChar = rowNotation[i];

        if (!isNaN(fenChar)) {
            let numEmptySquares = parseInt(fenChar);

            for (let j = 0; j < numEmptySquares; j++) {
                arr[idx] = "";
                idx++;
            }
        } else {
            arr[idx] = fenChar;
            idx++;
        }
    }

    return arr;
  }

  // Could create 8 by 8 array based on FEN notation
  const convertFEN = () => {
    // Initialize empty 8 by 8 array
    let arr = [];

    const piecePositions = fenString.split(" ")[0];
    const playerTurn = fenString.split(" ")[1];
    setColorToMove(playerTurn);

    let positionsByRow = piecePositions.split("/");

    // If it is black's move, reverse the array
    if (playerTurn === 'b') {
        // positionsByRow.reverse();
    }

    for (let i = 0; i < 8; i++) {
        arr.push(convertFENRow(positionsByRow[i]));
    }

    setPiecePositions(arr);
    setPiecesLoaded(true);
  }

  // Update FEN notation with move from black
  const animateFirstMove = (firstMove, secondMove) => {
    // Get second character of first string 
    // const firstMove = moves[0];

    // const firstSquare = moves[0].slice(0, 2);
    const firstSquare = firstMove;
    // const secondSquare = moves[0].slice(2);
    const secondSquare = secondMove;

    const rowNum = 8 - parseInt(firstMove.charAt(1));
    const colNum = letters.indexOf(firstMove.charAt(0));
    const colChar = letters[letters.indexOf(firstMove.charAt(0))];

    const pieceStr = piecePositions[rowNum][colNum];

    const idOfPiece = pieceStr + firstSquare;

    // Remove image from starting square
    const pieceImg = document.getElementById(idOfPiece);
    pieceImg.parentNode.removeChild(pieceImg);

    // Grab target square 
    const pieceTargetSquare = document.getElementById(secondSquare);

    // Check if target square has a piece
    if (pieceTargetSquare.childNodes[0].childNodes[0]) {
        const secondRowNum = 8 - parseInt(secondSquare.charAt(1));
        const secondColNum = letters.indexOf(secondSquare.charAt(0));
        const secondPieceStr = piecePositions[secondRowNum][secondColNum];
        // Grab target square
        const idOfSecondPiece = secondPieceStr + secondSquare;

        // Remove image from target square
        const secondPieceImage = document.getElementById(idOfSecondPiece);
        secondPieceImage.parentNode.removeChild(secondPieceImage);
    }
    pieceTargetSquare.childNodes[0].appendChild(pieceImg);
    pieceTargetSquare.style.background = "lightblue"

  }

  // For now this gets a random puzzle with 4 moves to complete   
  const getRandomPuzzle = async () => {
    const options = {
        method: 'GET',
        url: 'https://chess-puzzles.p.rapidapi.com/',
        params: {
            themes: '["mateIn1"]',
            themesType: 'ONE',
            playerMoves: '1',
        },
        headers: {
            'X-RapidAPI-Key': 'b183eb0c8dmsh556a7afa39657f0p191a00jsncfc74aa7eb2e',
            'X-RapidAPI-Host': 'chess-puzzles.p.rapidapi.com'
        }
    };

    if (fenString === "" && count === 0) {
        try {
            let response = await axios.request(options);
    
            while (true) {
                if (response.data.puzzles[0].fen.split(" ")[1] === "w") {
                    response = await axios.request(options);
                } else {
                    break;
                }
            }

            console.log(response.data.puzzles[0].fen)
            console.log(response.data.puzzles[0].moves)
            setFenString(response.data.puzzles[0].fen);
            setMoves(response.data.puzzles[0].moves)
            setFetchingPuzzle(false);

        } catch (err) {
            console.error(err);
            setError(err);
            setFetchingPuzzle(false);
        }
    }
  }

  const checkIfOppositeColor = (elem) => {
    return elem.id[0] !== elem.id[0].toUpperCase() ? true : false;
  }

  const checkIfPossibleMove = (elem) => {
    // Check for squares with pieces 
    if (elem.className === "chess-piece") {
        const squareContainer = elem.parentNode.parentNode;
        const hasRedBg = squareContainer.childNodes[2].style.display === "flex";
        // Check if highlighted red and opposite color
        if (checkIfOppositeColor(elem) && hasRedBg) {
            return { isPossible: true, elemId: elem.id };
        } else {
            return false;
        }
    } else if (elem.className === "circle") {
        return { isPossible: true, elemId: elem.parentNode.id };
    } else {
        // Check if square without piece has circle
        const hasCircle = elem.childNodes[1].style.display === "flex";
        if (hasCircle) {
            return { isPossible: true, elemId: elem.id };
        } else {
            return false;
        }
    }
  }

  const showElem = (elemId) => {
    document.getElementById(elemId).style.display = "flex";
  }

  const hideElem = (elemId) => {
    document.getElementById(elemId).style.display = "none";
  }

  const addTextToElem = (elemId, elemText) => {
    document.getElementById(elemId).textContent = elemText;
  }

  const showFeedback = (userMove, correctMove) => {
    // Shows right or wrong to user
    showElem("feedback");
    if (correctMove === userMove) {
        correctMoveMade = true;
        addTextToElem("feedback", "Right!")
    } else {
        correctMoveMade = false;
        addTextToElem("feedback", "Wrong!")

        // Show retry button
        showElem("retryButton")

        // Show see answer button
        showElem("seeAnswerButton")
    }

    showElem("nextPuzzleButton");
  }

  const showAnswer = () => {
    showElem("correctAnswerText");
    addTextToElem("correctAnswerText", "The correct answer is: " + moves[1].slice(2))
  }

//   const hideFeedback = () => {
//     hideElem("feedback")
//     hideElem("retryButton")
//     hideElem("seeAnswerButton")
//     hideElem("nextPuzzleButton")
//   }

  const ChessSquare = ({row, col}) => {
    const Square = ({color}) => {
        const handleMove = (e) => {
            const elem = e.target;
            
            // If own piece, set last piece clicked
            if (elem.className === "chess-piece" && !checkIfOppositeColor(elem)) {
                lastPieceClicked = elem;
            }
            
            if (checkIfPossibleMove(elem).isPossible) {
                const newSquareId = checkIfPossibleMove(elem).elemId;
                let newSquareElem = document.getElementById(newSquareId);
    
                // If opposing piece, take it and update UI
                if (newSquareElem.nodeName === "IMG") {
                    newSquareElem.src = lastPieceClicked.src;
                    newSquareElem.id = elem.id.slice(1);
                    lastPieceClicked.remove();
                } else {
                    // If no piece on the square, simply add piece to it
                    newSquareElem.appendChild(lastPieceClicked);
                }
    
                // Clear out moves from any previous clicks on a piece
                const squaresWithCircles = document.getElementsByClassName("circle");
                const squaresWithRedBg = document.getElementsByClassName("red-background");
                
                for (let i = 0; i < squaresWithCircles.length; i++) {
                    squaresWithCircles[i].style.display = 'none';
                    squaresWithRedBg[i].style.display = 'none';
                }
    
                const correctMove = moves[1].slice(2);

                showFeedback(newSquareElem.id, correctMove)
            }
        }
        
        return (
            <Box
                sx={
                    {
                        width: {
                            xs: "38px",
                            md: 70,
                        },
                        height: {
                            xs: "38px",
                            md: 70
                        },
                        backgroundColor: color,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',          
                }}
                id={colorToMove === 'b' ? `${letters[col]}${8 - row}` : `${reverseLetters[col]}${row + 1}`}
                onClick={(e) => { handleMove(e) }}
            >
                <Piece pieceString={piecePositions[row][col]} 
                       col={colorToMove === "b" ? letters[col] : reverseLetters[col]} 
                       row={colorToMove === "b" ? 8 - row : row + 1} />
                <div className="circle"></div>
                <div className="red-background"></div>
            </Box>
        )
    }

    return (
        <>
            {row % 2 === 0 ? 
                (
                    <>
                        {col % 2 === 0 ? 
                            (
                                <Square color={'#D0DCE1'} />
                            )
                            :
                            (
                                <Square color={'#091127'} />
                            )
                        }
                    </>
                )
                :
                (
                    <>
                        {col % 2 === 0 ? 
                            (
                                <Square color='#091127' />
                            )
                            :
                            (
                                <Square color='#D0DCE1' />
                            )
                        }
                    </>
                )
            }
        </>
    )
  }

  const Feedback = () => (
    <Container maxWidth="xs" >
        <CssBaseline />
          <Box sx={{    display: "flex", 
                        flexDirection: "column", 
                        alignItems: "center", 
                        justifyContent: "center", 
                        minHeight: "100%" ,
                        marginLeft: {xs: "0px", sm: "150px"}
                    }}>
                <Box sx={{  
                            display: "flex", 
                            flexDirection: "column", 
                            alignItems: "center", 
                            justifyContent: "center", 
                            mb: 4 
                        }}>
                    <Typography id="feedback"  sx={{marginBottom: {xs: "-20px", sm: "10px"}, marginTop: "20px", fontSize: {xs: "20px", sm: "35px"}}}></Typography>
                    <Typography id="correctAnswerText"  sx={{ marginBottom: {xs: "0px", sm: "20px"}, marginTop: { xs: "32px" }, fontSize: {xs: "20px", sm: "30px"} }}></Typography>
                </Box>
                <Box
                    sx={{
                        minWidth: {xs: '100%', sm: '50%'},
                        display: 'flex',
                        flexDirection: {xs: 'column', sm: 'row'},
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Button       
                        id="retryButton"
                        onClick={() => { convertFEN() }}
                        variant="contained"
                    >
                        Retry
                    </Button>
                    <Button
                        id="seeAnswerButton"
                        variant="contained"
                        onClick={() => {showAnswer()}}
                    >
                        See Answer
                    </Button>
                    <Button
                        id="nextPuzzleButton"
                        variant="contained"
                        onClick={() => { window.location.reload(false) }}
                    >
                        Next Puzzle
                </Button>
                </Box>
          </Box>
      </Container>
  )
    
  useEffect(() => {
    // if (fenString === "") {
        getRandomPuzzle();
        count++;
    // }

    if (fenString !== "") {
        convertFEN()
    }
  }, [fetchingPuzzle])

  useEffect(() => {
    if (piecePositions !== null) {
        // Highlight move by black
        setTimeout(() => {
            document.getElementById(moves[0].slice(0, 2)).style.backgroundColor = "lightblue";
        }, 1000)

        setTimeout(() => {
            animateFirstMove(moves[0].slice(0, 2), moves[0].slice(2))
        }, 2000)
    }
  }, [piecePositions])


  return (
        <>
            {piecesLoaded ? 
                (
                    <Box    
                            sx={{ display: "flex", 
                               minWidth: "100%",
                               marginLeft: 0,
                               flexDirection: {xs: "column", sm: "row"}                           
                            }}
                            >
                        <Stack direction={"column"} sx={{marginRight: "100px", minWidth: "100%"}}>
                            {Array.from(Array(8)).map((_, row) => (
                                <Stack direction={"row"}>
                                    {Array.from(Array(8)).map((_, col) => (
                                        <ChessSquare row={row} col={col} />
                                    ))}
                                </Stack>
                            ))}
                        </Stack>
                        <Feedback />
                    </Box>
                )
                :
                error ? 
                (
                    <Box sx={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                        <Typography variant="h6">Sorry, there was an error with the API.</Typography>

                        <Button       
                            onClick={window.location.reload(false)}
                            variant="contained"
                            sx={{marginTop: 5}}
                            
                        >
                            Reload
                        </Button>
                    </Box>
                )
                :
                (
                    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", minWidth: "100%", minHeight: "100%"}}>
                        <CircularProgress />
                    </Box>
                )
            }
        </>
    )
}

export default Board