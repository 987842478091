import { useEffect } from "react";
import Home from "./components/landing/Home";
import SignIn from "./components/auth/signIn/SignIn";
import SignUp from "./components/auth/signUp/SignUp";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material/styles";

import UserRoute from "./components/misc/UserRoute";

import { useDispatch } from "react-redux";

import { auth } from "./firebase";

import { setUser } from "./redux/actions";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#0d1939",
    },
    secondary: {
      main: "#d0a84c",
    },
  },
});

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Routes>
            <Route exact path="/" element={<Home />} />
            {/* <UserRoute exact path="/" element={<Home />} /> */}
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
          </Routes>
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
