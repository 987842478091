import React, { useState } from 'react'
import "./piece.css"

// pieceString is a param that is a single character based on FEN notation for now
// capital letters are white pieces, lowercase letters are black pieces
const Piece = ({ pieceString, row, col }) => {
  const letters = ["a", "b", "c", "d", "e", "f", "g", "h"];
  const rowNums = [1, 2, 3, 4, 5, 6, 7, 8]
  let imgURL = "";

  // If character equals uppercase version of itself, it is uppercase and a WHITE piece 
  if (pieceString === pieceString.toUpperCase()) {
    imgURL = "white" + pieceString + ".png";
  } else {
    imgURL = "Black" + pieceString + ".png";
  }

  const checkIfPiece = (squareName) => {
    let hasPiece = false;
    let pieceColor = "";

    const square = document.getElementById(squareName); 
    let pieceOnSquare;

    // Check if square is off board
    if (square !== null) {
        pieceOnSquare = square.childNodes[0].childNodes[0];
    
        // Clean this up later
        if (pieceOnSquare !== undefined) {
            // console.log(pieceOnSquare)
            pieceColor = pieceOnSquare.id[0] === pieceOnSquare.id[0].toUpperCase() ? "white" : "black";
            hasPiece = true;
        } 
    }

    // console.log(hasPiece);
    if (hasPiece) {
        // console.log(pieceOnSquare)
        return {"hasPiece": true, "pieceID": pieceOnSquare, "pieceColor": pieceColor };
    } else {
        return {"hasPiece": false };
    }
  }

  const getPieceAttributes = (pieceString) => {
    switch (pieceString) {
        case "P":
            return { piece: "pawn", color: "white" };
        case "p":
            return { piece: "pawn", color: "black" };
        case "N":
            return { piece: "knight", color: "white" };
        case "n":
            return { piece: "knight", color: "black" };
        case "B":
            return { piece: "bishop", color: "white" };
        case "b":
            return { piece: "bishop", color: "black" };
        case "R":
            return { piece: "rook", color: "white" };
        case "r":
            return { piece: "rook", color: "black" };
        case "Q":
            return { piece: "queen", color: "white" };
        case "q":
            return { piece: "queen", color: "black" };
        case "K":
            return { piece: "king", color: "white" };
        case "k":
            return { piece: "king", color: "black" };
    }
  }
  
  const checkIfSquareExists = (colLetter, rowNum) => {
    if (letters.indexOf(colLetter) !== undefined || rowNums.indexOf(rowNum) !== -1) {
        return true;
    } else {
        return false;
    }
  }

  const getPossibleDiagnolMoves = () => {
    const pieceAttributes = getPieceAttributes(pieceString);
    const pieceColor = pieceAttributes.color;
    const pieceType = pieceAttributes.piece;

    const multiplier = pieceColor === "white" ? 1 : -1;
    let movesArr = [];

    if (pieceColor === "white") {
        const nextColIdx = letters.indexOf(col) + (1 * multiplier);
        const prevColIdx = letters.indexOf(col) - (1 * multiplier);
    
        let nextRowIdx = row + (1 * multiplier);
        let prevRowIdx = row - (1 * multiplier);

        // Forward right moves
        for (let i = nextColIdx; i < 8; i++) {
            const newCol = letters[i];

            const newSquare = newCol + nextRowIdx;

            if (checkIfPiece(newSquare).hasPiece === true) {
                if (checkIfPiece(newSquare).pieceColor !== pieceColor) {
                    movesArr.push(newSquare);
                }
                break;
            }

            // Check if square exists
            if (checkIfSquareExists(newCol, nextRowIdx)) {
                movesArr.push(newSquare);
            }

            nextRowIdx++;       
        }

        // Reassign next row idx
        nextRowIdx = row + (1 * multiplier);

        // Forward left moves
        for (let i = prevColIdx; i >= 0; i--) {
            const newCol = letters[i];

            const newSquare = newCol + nextRowIdx;

            if (checkIfPiece(newSquare).hasPiece === true) {
                if (checkIfPiece(newSquare).pieceColor !== pieceColor) {
                    movesArr.push(newSquare);
                }
                break;
            }

            // Check if square exists
            if (checkIfSquareExists(newCol, nextRowIdx)) {
                movesArr.push(newSquare);
            }

            nextRowIdx++;       
        }

        // Backwards right moves
        for (let i = nextColIdx; i < 8; i++) {
            const newCol = letters[i];

            const newSquare = newCol + prevRowIdx;

            if (checkIfPiece(newSquare).hasPiece === true) {
                if (checkIfPiece(newSquare).pieceColor !== pieceColor) {
                    movesArr.push(newSquare);
                }
                break;
            }

            // Check if square exists
            if (checkIfSquareExists(newCol, prevRowIdx)) {
                movesArr.push(newSquare);
            }

            prevRowIdx--; 
        }

        // Reassign next row idx
        prevRowIdx = row - (1 * multiplier);

        // Backwards right moves
        for (let i = prevColIdx; i >= 0; i--) {
            const newCol = letters[i];

            const newSquare = newCol + prevRowIdx;

            if (checkIfPiece(newSquare).hasPiece === true) {
                if (checkIfPiece(newSquare).pieceColor !== pieceColor) {
                    movesArr.push(newSquare);
                }
                break;
            }

            // Check if square exists
            if (checkIfSquareExists(newCol, prevRowIdx)) {
                movesArr.push(newSquare);
            }

            prevRowIdx--; 
        }
    } else {
        const nextColIdx = letters.indexOf(col) - 1;
        const prevColIdx = letters.indexOf(col) + 1;
    
        let nextRowIdx = row - 1;
        let prevRowIdx = row + 1;

        // Forward right moves
        for (let i = nextColIdx; i >= 0; i--) {
            const newCol = letters[i];

            const newSquare = newCol + nextRowIdx;

            if (checkIfPiece(newSquare).hasPiece === true) {
                if (checkIfPiece(newSquare).pieceColor !== pieceColor) {
                    movesArr.push(newSquare);
                }
                break;
            }

            // Check if square exists
            if (checkIfSquareExists(newCol, nextRowIdx)) {
                movesArr.push(newSquare);
            }

            nextRowIdx--;       
        }

        // Reassign next row
        nextRowIdx = row - 1;

        // Forward left moves
        for (let i = prevColIdx; i < 8; i++) {
            const newCol = letters[i];

            const newSquare = newCol + nextRowIdx;
            // console.log(newSquare)

            if (checkIfPiece(newSquare).hasPiece === true) {
                if (checkIfPiece(newSquare).pieceColor !== pieceColor) {
                    movesArr.push(newSquare);
                }
                break;
            }

            // Check if square exists
            if (checkIfSquareExists(newCol, nextRowIdx)) {
                movesArr.push(newSquare);
            }

            nextRowIdx--;       
        }

        // Backwards right moves
        for (let i = nextColIdx; i >= 0; i--) {
            const newCol = letters[i];

            const newSquare = newCol + prevRowIdx;

            if (checkIfPiece(newSquare).hasPiece === true) {
                if (checkIfPiece(newSquare).pieceColor !== pieceColor) {
                    movesArr.push(newSquare);
                }
                break;
            }

            // Check if square exists
            if (checkIfSquareExists(newCol, prevRowIdx)) {
                movesArr.push(newSquare);
            }

            prevRowIdx++;       
        }

        // Reassign next row
        prevRowIdx = row + 1;
        // console.log(prevRowIdx)

        // Backwards left moves
        for (let i = prevColIdx; i < 8; i++) {
            const newCol = letters[i];

            const newSquare = newCol + prevRowIdx;
            // console.log(newSquare)

            if (checkIfPiece(newSquare).hasPiece === true) {
                if (checkIfPiece(newSquare).pieceColor !== pieceColor) {
                    movesArr.push(newSquare);
                }
                break;
            }

            // Check if square exists
            if (checkIfSquareExists(newCol, prevRowIdx)) {
                movesArr.push(newSquare);
            }

            prevRowIdx++;       
        }
    }
    return movesArr;
  }
  
  const getPossibleStraightLineMoves = () => {
    const pieceAttributes = getPieceAttributes(pieceString);
    const pieceColor = pieceAttributes.color;
    const pieceType = pieceAttributes.piece;

    let movesArr = [];

    const nextColIdx = letters.indexOf(col) + 1;
    const prevColIdx = letters.indexOf(col) - 1;

    let nextRowIdx = row + 1;
    let prevRowIdx = row - 1;

    // Check for moves to right 
    for (let i = nextColIdx; i < 8; i++) {
        const newCol = letters[i];

        const newSquare = newCol + row;

        if (checkIfPiece(newSquare).hasPiece === true) {
            if (checkIfPiece(newSquare).pieceColor !== pieceColor) {
                movesArr.push(newSquare);
            }
            break;
        }

        // Check if square exists
        if (checkIfSquareExists(newCol, row)) {
            movesArr.push(newSquare);
        }   
    }

    // Check for moves to left 
    for (let i = prevColIdx; i >= 0; i--) {
        const newCol = letters[i];

        const newSquare = newCol + row;

        if (checkIfPiece(newSquare).hasPiece === true) {
            if (checkIfPiece(newSquare).pieceColor !== pieceColor) {
                movesArr.push(newSquare);
            }
            break;
        }

        // Check if square exists
        if (checkIfSquareExists(newCol, row)) {
            movesArr.push(newSquare);
        }   
    }

    // Check for moves in front 
    for (let i = nextRowIdx; i <= 8; i++) {
        const newSquare = col + i;

        if (checkIfPiece(newSquare).hasPiece === true) {
            if (checkIfPiece(newSquare).pieceColor !== pieceColor) {
                movesArr.push(newSquare);
            }
            break;
        }

        // Check if square exists
        if (checkIfSquareExists(col, nextRowIdx)) {
            movesArr.push(newSquare);
        }   
    }

    // Check for moves behind 
    for (let i = prevRowIdx; i >= 0; i--) {
        const newSquare = col + i;

        if (checkIfPiece(newSquare).hasPiece === true) {
            if (checkIfPiece(newSquare).pieceColor !== pieceColor) {
                movesArr.push(newSquare);
            }
            break;
        }

        // Check if square exists
        if (checkIfSquareExists(col, i)) {
            movesArr.push(newSquare);
        }   
    }
    
    return movesArr;
  }

  

  const getPossibleMoves = (pieceString) => {
    let movesArr = [];
    // Extract piece attributes
    const pieceAttributes = getPieceAttributes(pieceString);
    const pieceColor = pieceAttributes.color;
    const pieceType = pieceAttributes.piece;

    // If white, moves will increase positively, and if black, moves will increase negatively
    const multiplier = pieceColor === "white" ? 1 : -1;

    switch (pieceType) {
        case "pawn":
            // Check if piece on square (up one right one, up one left one)
            let squareIDRight = letters[letters.indexOf(col) + (1 * multiplier)] + parseInt(row + (1 * multiplier)) 
            let squareIDLeft = letters[letters.indexOf(col) - (1 * multiplier)] + parseInt(row + (1 * multiplier));

            let squareInfoRight = checkIfPiece(squareIDRight);
            let squareInfoLeft = checkIfPiece(squareIDLeft);

            if (squareInfoRight.hasPiece === true) {
                // Modify this to just get piece color (make function)
                if (squareInfoRight.pieceColor !== pieceColor) {
                    movesArr.push(squareIDRight);
                }
            }

            if (squareInfoLeft.hasPiece === true) {
                if (squareInfoLeft.pieceColor !== pieceColor) {
                    movesArr.push(squareIDLeft);
                }
            }

            // Check if white pawn can move two squares
            if (row === 2 || row === 7) {
                // Get square in front
                let oneSquareAhead = letters[letters.indexOf(col)] + parseInt(row + (1 * multiplier));
                let twoSquaresAhead = letters[letters.indexOf(col)] + parseInt(row + (2 * multiplier));

                if (checkIfPiece(oneSquareAhead).hasPiece === false) {
                    movesArr.push(oneSquareAhead);
                } 
                
                if (checkIfPiece(oneSquareAhead).hasPiece === false && checkIfPiece(twoSquaresAhead).hasPiece === false) {
                    movesArr.push(twoSquaresAhead);
                } 
            } else {
                let oneSquareAhead = letters[letters.indexOf(col)] + parseInt(row + (1 * multiplier));
                if (checkIfPiece(oneSquareAhead).hasPiece === false) {
                    movesArr.push(oneSquareAhead);
                } 
            }

            break;
        case "knight":
            // Return possible moves for knights

            // 8 possible; only time it can't move to a square is when another piece of its own color is there
            // Coordinate changes are moves in "L" shape (coordinate changes are for white knight)
            const possibleSquareChanges = [[-1, 2], [1, 2], [-2, 1], [2, 1], [-2, -1], [2, -1], [-1, -2], [1, -2]]

            possibleSquareChanges.forEach(squareChange => {
                const colChange = squareChange[0] * multiplier;
                const rowChange = squareChange[1] * multiplier;
    
                const newCol = letters[letters.indexOf(col) + (colChange)];
                const newRow = row + rowChange;

                // Make sure coordinates are on board
                if (newCol !== undefined && rowNums.indexOf(newRow) !== -1) {
                    // Check if piece of own color is on potential move
                    const newSquare = newCol + newRow;

                    if (checkIfPiece(newSquare).pieceColor !== pieceColor) {
                        movesArr.push(newSquare);
                    }
                }
            })
            break;
        case 'bishop':
            // Return possible moves for bishops, which can move in four possible directions:
            // +1 col +1 row (forward right)
            // -1 col +1 row (forward left)
            // +1 col -1 row (backwards right)
            // -1 col -1 row (backwards left)
            movesArr = getPossibleDiagnolMoves();
            
            break;
        case 'rook':
            // Return possible moves for rooks, which can move in four possible directions:
            // +0 col +1 row (forward)
            // +0 col -1 row (back)
            // +1 col +0 row (right)
            // -1 col +0 row (left)
            movesArr = getPossibleStraightLineMoves();

            break;
        case 'queen':
            // Return possible moves for queen; moves orthagonally (combination of rook and bishop)
            movesArr = getPossibleStraightLineMoves().concat(getPossibleDiagnolMoves());
            break;
        case 'king':
            // Return possible moves for king
            break;
    }

    if (pieceColor === "white") {
        // Clear out moves from any previous clicks on a piece
        let squaresWithCircles = document.getElementsByClassName("circle");
        let squaresWithRedBg = document.getElementsByClassName("red-background");
        
        for (let i = 0; i < squaresWithCircles.length; i++) {
            squaresWithCircles[i].style.display = 'none';
            squaresWithRedBg[i].style.display = 'none';
        }
        
        movesArr.forEach((move) => {
            const possibleSquareForMove = document.getElementById(move);
        
            // Check if square is valid; repeat logic ??
            if (possibleSquareForMove !== null) {
                // clean this up
                const potentialPiece = possibleSquareForMove.childNodes[0].childNodes
                const hasOpposingPiece = potentialPiece.length !== 0 ? true : false;
        
                if (hasOpposingPiece) {
                    const redBgDisplayStyle = getComputedStyle(possibleSquareForMove.childNodes[2]);
        
                    if (redBgDisplayStyle.display === "none") {
                        possibleSquareForMove.childNodes[2].style.display = "flex";
                    } 
                } else {
                    const circleDisplayStyle = getComputedStyle(possibleSquareForMove.childNodes[1]);
                        
                    if (circleDisplayStyle.display === "none") {
                        possibleSquareForMove.childNodes[1].style.display = "flex";
                    } else {
                        possibleSquareForMove.childNodes[1].style.display = "none";
                    }
                }
            }
        })
    }

  }

  return (
    <div style={{display: "flex"}}>
     {pieceString === "" ? 
            (<></>)
        :
            (
                <img src={require("../../assets/2000px-Chess_Pieces_Sprite_" + imgURL)} 
                    alt={pieceString} 
                    className="chess-piece" 
                    id={`${pieceString}${col}${row}`} 
                    onClick={() => {getPossibleMoves(pieceString)}}
                />
            )

      }
   
    </div>
  )
}

export default Piece