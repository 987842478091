import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import PeopleIcon from '@mui/icons-material/People';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';

import { FaChessPawn } from 'react-icons/fa';
import { FaChessKing } from 'react-icons/fa';


export const mainListItems = (
    <React.Fragment>
      <ListItemButton>
        <ListItemIcon>
          <FaChessKing style={{height: "20px", marginLeft: ".25rem"}} />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
      {/* <ListItemButton>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Stats" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <QueryStatsIcon />
        </ListItemIcon>
        <ListItemText primary="Train" />
      </ListItemButton> */}
    </React.Fragment>
);